import BaseService, { APIResponse } from "./BaseService";

export type TorneiResponse = {
  'id':number
  'nome':string
  'descrizione':string
  'data_inizio_torneo': string
  'data_fine_torneo': string
  'data_inizio_iscrizione': string
  'data_fine_iscrizione': string
  'giorni_settimana': any
  'orario': string
  'strutturaId': number
  'max_squadre': number
  'min_squadre': number
  'rapporto_partecipanti': string
  'premi': string
  'prezzo':number
  'prezzoString': string
  'squadre': {
    'id': number
    'nome': string
    'capitano': string
    'squadra': string
  }[]
  'sport': {
    'id': number
    'nome': string
  }
};

export default class TorneiService extends BaseService {
  static async getTornei(idStrutturna: number): Promise<APIResponse<TorneiResponse[]>> {
    return await this.perform<TorneiResponse[]>({
      url:'/operatore/tornei/'+ idStrutturna,
      method:'get'
    })
  }
  static async addTorneo(body: any): Promise<APIResponse<TorneiResponse[]>> {
    return await this.perform<TorneiResponse[]>({
      url:'/operatore/tornei',
      method:'post',
      body: body
    })
  }
  static async deleteTorneo(id_torneo: number): Promise<APIResponse<any>> {
    return await this.perform<any>({
      url:'/operatore/tornei/annullatorneo/'+id_torneo,
      method:'post'
    })
  }

  static async updateTorneo(id_torneo: number, body: any): Promise<APIResponse<any>> {
    return await this.perform<any>({
      url:'/operatore/tornei/modifica/'+id_torneo,
      method:'post',
      body: body
    })
  }
}

