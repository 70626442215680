






















































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import SportService from "@/services/SportService";
import StruttureService, {StruttureResponse} from "@/services/StruttureService";
import TorneiService from "@/services/TorneiService";

class Form {
  nome: string
  descrizione: string
  data_inizio_torneo: string
  data_fine_torneo: string
  data_inizio_iscrizione: string
  data_fine_iscrizione: string
  giorni_settimana: any[]
  orario: string
  durata: number
  id_struttura: number
  max_squadre: number
  min_squadre: number
  premi: string
  prezzo: number
  sport: {
    id: number
  }

  public constructor() {
    this.nome = ""
    this.descrizione = ""
    this.data_inizio_torneo = ""
    this.data_fine_torneo = ""
    this.data_inizio_iscrizione = ""
    this.data_fine_iscrizione = ""
    this.giorni_settimana = []
    this.orario = ""
    this.durata = 0
    this.id_struttura = 0
    this.max_squadre = 0
    this.min_squadre = 0
    this.premi = ""
    this.prezzo = 0
    this.sport = {
      id: 0
    }
  }
}

@Component
export default class AddTournament extends Vue {

  public form: Form = new Form();
  public dateTorneo: Date[] = []
  public dateIscrizione: Date[] = []
  public prezzo: number = 1
  public listaSport: any = []
  public datiStruttura: StruttureResponse = []

  giorniSettimana = [false, false, false, false, false, false, false]

  giorniOptions = [
    {"id": 0, "label": "Lunedì"},
    {"id": 1, "label": "Martedì"},
    {"id": 2, "label": "Mercoledì"},
    {"id": 3, "label": "Giovedì"},
    {"id": 4, "label": "Venerdì"},
    {"id": 5, "label": "Sabato"},
    {"id": 6, "label": "Domenica"}
  ];

  durate_prenotazioni = [30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420]

  public datePickerOptions: any = {
    disabledDate(date: any) {
      return date < new Date().setDate(new Date().getDate() - 1)
    }
  }

  public async RiceviSport() {
    const response = await SportService.ListaSportStruttura(this.form.id_struttura)
    this.listaSport = response.data.map(x => {
      return {
        nome: x.nome,
        id: x.id,
      }
    })
    this.form.sport.id = this.listaSport[0].id
  }

  public async RiceviStrutture() {
    try {
      const response = await StruttureService.getStrutture();

      if (response.error) {
        this.$message.error(response.msg)
      } else {
        this.datiStruttura = response.data;
        this.form.id_struttura = this.datiStruttura[0].struttura.value
      }
    } catch (err: any) {

    }
  }

  public async CreaTorneo() {
    this.form.data_inizio_torneo = this.dateTorneo[0].toISOString()
    this.form.data_fine_torneo = this.dateTorneo[1].toISOString()
    this.form.data_inizio_iscrizione = this.dateIscrizione[0].toISOString()
    this.form.data_fine_iscrizione = this.dateIscrizione[1].toISOString()
    this.form.prezzo = this.prezzo * 100
    for (let i = 0; i < this.form.giorni_settimana.length; i++) {
      this.giorniSettimana[this.form.giorni_settimana[i]] = true
    }
    this.form.giorni_settimana = this.giorniSettimana

    console.log(this.form.sport)

    const response = await TorneiService.addTorneo(this.form)
    if(response.error) {
      this.$message.error(response.msg)
    } else {
      this.$message.success("Creazione andata a buon fine")
      await this.creaPrenotazioni()
      await this.$router.push('/tornei')
    }
  }

  async creaPrenotazioni(){
    try {
      this.$confirm("Si ricorda che per bloccare gli slot orari dei campi su cui si svolgerà il torneo è necessario procedere con una prenotazione multipla per ogni campo coinvolto", "Attenzione", {
        confirmButtonText: "Avanti",
      }).then(async () => {
      }).catch(() => {
        this.$message({
          type: 'warning',
          message: "L'elemento non è stato eliminato"
        })
      })
    } catch (error: any) {

    }
  }

  @Watch('form.min_squadre')
  updateMaxSquadre() {
    this.form.max_squadre = this.form.min_squadre
  }

  async mounted() {
    await this.RiceviStrutture();
    await this.RiceviSport()
  }

}
